.updateProfile {
    min-height: 100vh;
    background-image: linear-gradient(to right, #fff5bc, #cec1ff);
  
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2vmax;
    box-sizing: border-box;
  }
  
  .updateProfileForm {
    background-color: white;
    width: 50%;
    border-radius: 30px;
    box-sizing: border-box;
    padding: 2vmax;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .updateProfileInputs {
    box-sizing: border-box;
    padding: 1vmax 2vmax;
    width: 80%;
    border-radius: 40px;
    border: 1px solid #ccc;
    margin: 2vmax;
    font: 100 1.2rem "Roboto", sans-serif;
    outline: none;
  }
  
  .updateProfileForm > input[type="file"] {
    border-radius: 30px;
    margin: 2vmax;
  }
  
  .updateProfileForm > input[type="file"]::-webkit-file-upload-button {
    background-color: rgb(38, 63, 173);
    color: white;
    width: 100%;
    padding: 1vmax;
    border-radius: 30px;
    border: none;
    font: 100 1.2rem "Roboto", sans-serif;
    cursor: pointer;
  }
  
  @media screen and (max-width: 600px) {
    .updateProfileForm {
      width: 100%;
    }
  
    .updateProfileForm > input {
      padding: 4vw 8vw;
      margin: 2vmax;
      font: 100 1.2rem "Roboto", sans-serif;
      margin: 4vw;
    }
  
    .updateProfileForm > input[type="file"] {
      width: 100%;
    }
  
    .updateProfileForm > input[type="file"]::-webkit-file-upload-button {
      width: 70%;
    }
  }