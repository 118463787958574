.commentUser {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.507);
  }
  
  .commentUser > a {
    display: flex;
    text-decoration: none;
    color: rgb(0, 0, 0);
    align-items: center;
    align-self: flex-start;
    transition: all 0.5s;
  }
  .commentUser img {
    height: 3vmax;
    width: 3vmax;
    margin: 1vmax;
    border-radius: 100%;
    border: 3px solid rgb(255, 201, 201);
  }
  
  .commentUser > a:hover {
    transform: translateY(-10px);
  }
  
  .commentUser > button {
    color: red;
  }